import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListSubheader from '@material-ui/core/ListSubheader';

import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import HeaderSection from "../HeaderSection";



const ContactForm = ({ formState, handleChange, dates, classes, step, handleAgree, isAgreed, setFormState }) => {

  console.log('dates', dates)


  return (
    <>
      {step === 0 && <>

        <Grid item xs={12} >
          <InputLabel id="reason-select-label">
            Reason
          </InputLabel>
          <Select
            className={classes.selectCourses}
            labelId="reason-select-label"
            id="reason-select"
            name="reason"
            value={formState?.reason}
            onChange={handleChange}
          >
            <MenuItem value="offer">
              {" Get an offer (angebot)"}
            </MenuItem>
            <MenuItem value="application">
              Apply for a course
            </MenuItem>
            <MenuItem value="corporate">
              Corporate training
            </MenuItem>
            <MenuItem value="other">
              Other
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} >
          {formState?.reason === "other" && <TextField
            type="text"
            id="reason"
            name="reasonDescription"
            label="Let us know how we can help"
            fullWidth
            value={formState?.reasonDescription}
            onChange={handleChange}
          />}
        </Grid>
      </>
      }
      {
        step === 1 && <>
          {formState.reason === "offer" && <Grid item xs={12}>
            Please fill out the all the fields below to get an offer (angebot).
          </Grid>}
          <Grid item xs={12} sm={6}>
            <InputLabel id="course-select-label">
              Course
            </InputLabel>
            <Select
              className={classes.selectCourses}
              labelId="course-select-label"
              id="course-select"
              name="course"
              value={formState?.course ? formState.course : ""}
              onChange={handleChange}
            >
              <MenuItem value="unsure">
                Unsure
              </MenuItem>
              <ListSubheader color="primary">Web Development</ListSubheader>
              <MenuItem value="web3">
                Front-End Development - 3 months
              </MenuItem>
              <MenuItem value="web4">
                Full-Stack Development - 4 months
              </MenuItem>
              <MenuItem value="web5">
                Advanced Full-Stack Development - 5 months
              </MenuItem>
              <ListSubheader color="primary">Data Science</ListSubheader>
              <MenuItem value="data3">
                Data Analytics - 3 months
              </MenuItem>
              <MenuItem value="data4">
                Advanced Data Analytics - 4 months
              </MenuItem>
              <MenuItem value="data3">
                Data Science & Machine Learning - 5 months
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel id="date">
              Desired start date*
            </InputLabel>
            <Select
              className={classes.selectCourses}
              labelId="date"
              id="date"
              name="date"
              value={formState?.date ? formState.date : ""}
              onChange={handleChange}
              disabled={formState?.course ? false : true}
            >
              {
                // formState?.course === "unsure"
                //   ? [...new Set(dates.map((item) => item))].map((start) => {
                //     console.log('start', start)
                //     return (
                //       <MenuItem key={start.course} value={start.date}>
                //         {start.fdate}
                //       </MenuItem>
                //     );
                //   })
                //   :

                formState?.course &&
                dates
                  .filter(start => new Date(start.date) > new Date()
                    && formState?.course.includes(start.course))
                  .map(start =>
                    <MenuItem key={start.date} value={start.date}>
                      {start.fdate}
                    </MenuItem>

                  )
              }
              <MenuItem value="unsure">
                Unsure
              </MenuItem>
            </Select>
          </Grid></>
      }
      {
        step === 0 && <><Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstname"
            label="Firstname"
            fullWidth
            autoComplete="name"
            value={formState?.firstname ? formState?.firstname : ""}
            onChange={handleChange}
          />
        </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastname"
              name="lastname"
              label="Last name"
              fullWidth
              autoComplete="family-name"
              value={formState?.lastname ? formState?.lastname : ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              type="email"
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="email"
              value={formState?.email ? formState?.email : ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              // required
              type="tel"
              id="phone"
              name="phone"
              label="Phone number"
              fullWidth
              autoComplete="tel"
              value={formState?.phone ? formState?.phone : ""}
              onChange={handleChange}
            />
          </Grid>
        </>
      }


      {
        step === 1 && <><Grid item xs={12} sm={6}>
          <InputLabel id="bildungsgutschein-label">
            Are you eligible for a Bildungsgutschein?*
          </InputLabel>
          <Select
            className={classes.selectCourses}
            labelId="bildungsgutschein-label"
            id="bildugsgutschein"
            name="bildungsgutschein"
            value={
              formState?.bildungsgutschein ? formState.bildungsgutschein : ""
            }
            onChange={handleChange}
            required
          >
            <MenuItem value="haveBildungsgutschein">
              I already have it
            </MenuItem>
            <MenuItem value="verbalApprovalBildungsgutschein">
              I have verbal approval for it
            </MenuItem>
            <MenuItem value="inProcessBildungsgutschein">
              I am in the process of applying for it
            </MenuItem>
            <MenuItem value="dontknowBildungsgutschein">
              I don't know and I would like to learn more
            </MenuItem>
            <MenuItem value="noBildungsgutschein">
              I am not eligible for it
            </MenuItem>
          </Select>
        </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="text"
              id="kundennummer"
              name="kundennummer"
              label="Job center number (Kundennummer)"
              fullWidth
              value={formState?.kundennummer}
              onChange={handleChange}
            />
          </Grid>
        </>
      }
      {
        step === 2 && <><Grid item xs={12} sm={6}>
          <InputLabel id="marketing-funnel-label">
            Where did you hear about us?*
          </InputLabel>
          <Select
            className={classes.selectCourses}
            labelId="marketing-funnel-label"
            id="marketing-funnel"
            name="marketingfunnel"
            value={formState?.marketingfunnel ? formState.marketingfunnel : ""}
            onChange={handleChange}

          >
            <MenuItem value="personalReferral">
              Personal referral
            </MenuItem>
            <MenuItem value="google">
              Google
            </MenuItem>
            <MenuItem value="switchup">
              Switch-Up
            </MenuItem>
            <MenuItem value="coursereport">
              Course Report
            </MenuItem>
            <MenuItem value="kursenet">
              Kursenet
            </MenuItem>
            <MenuItem value="wdb">
              WDB
            </MenuItem>
            <MenuItem value="linkedin">
              LinkedIn
            </MenuItem>
            <MenuItem value="facebook">
              Facebook
            </MenuItem>
            <MenuItem value="instagram">
              Instagram
            </MenuItem>
            <MenuItem value="other">
              Other
            </MenuItem>
          </Select>
        </Grid>
          <Grid item xs={12}>
            <TextField
              id="msg"
              name="msg"
              label="Message"
              fullWidth
              multiline
              rows="4"
              value={formState?.msg ? formState?.msg : ""}
              onChange={handleChange}
            />
          </Grid></>
      }
      {
        step === 1 && <><Grid item xs={12}>
          <FormControlLabel
            value={formState.city === "Berlin" ? true : false}
            onChange={() => setFormState({ ...formState, city: formState.city === "Berlin" ? "" : "Berlin" })}
            control={<Checkbox defaultChecked color="secondary" name="berlin" />}
            label="I am currently residing in Berlin"
          />
        </Grid>
          {formState.city !== "Berlin" && <Grid item xs={12}>
            Please be aware that our courses are fully on-site in Berlin and we do not offer any remote courses. We do not provide any visa or accommodation support.
          </Grid>}</>
      }
      {
        step === 2 && <><Grid item xs={12}>
          <FormControlLabel
            value={isAgreed}
            onChange={handleAgree}
            control={<Checkbox color="secondary" name="agree" />}
            label="I agree to terms and usage of my data. Code Academy Berlin will never sell or use these information for commercial purposes."
          />
        </Grid>
          {/* <Grid item xs={12} className={classes.flexCentered}>
            <Button
              disabled={
                isAgreed &&
                  formState.course &&
                  formState.date &&
                  formState.firstname &&
                  formState.lastname &&
                  formState.email &&
                  // formState.phone &&
                  // formState.marketingfunnel &&
                  formState.bildungsgutschein
                  ? false
                  : true
              }
              type="submit"
              variant="outlined"
              className={
                isAgreed &&
                  formState.course &&
                  formState.date &&
                  formState.firstname &&
                  formState.lastname &&
                  formState.email &&
                  // formState.phone &&
                  // formState.marketingfunnel &&
                  formState.bildungsgutschein
                  ? classes.button
                  : classes.buttonDisabled
              }
              disableElevation
              color="primary"
            // onClick={handleSubmit}
            >
              Send Application
            </Button>
          </Grid> */}
        </>
      }
    </>
  );
};

// const ContactFormNextStartsQuery = ({ location, submit, course, date, classes, step }) => (
//   <StaticQuery
//     query={graphql`
//       query ContactFormNextStartsDateQuery {
//         allMarkdownRemark(
//           filter: { frontmatter: { key: { eq: "nextStarts" } } }
//         ) {
//           edges {
//             node {
//               frontmatter {
//                 nextStarts {
//                   date
//                   fdate(formatString: "DD MMMM YYYY")
//                   course
//                 }
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={(data, count) => (
//       <ContactForm
//         location={location}
//         data={data}
//         submit={submit}
//         course={course}
//         date={date}
//         classes={classes}
//         count={count}
//         step={step}
//       />
//     )}
//   />
// );

// export default withStyles(styles)(ContactFormNextStartsQuery);
export default withStyles(styles)(ContactForm);
