import React, { useState } from "react";
import Layout from "../../components/Layout";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import HeaderSection from "../../components/HeaderSection";
import BookingModal from "../../components/BookingModal/BookingModal";
import ContactForm from "../../components/ContactForm";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(4),
    minHeight: "90vh",
    display: "flex",
    gap: theme.spacing(4),
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Personal information', 'Course information', 'Additional information'];
}



const ContactPage = ({ data, location }) => {
  const { nextStarts } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const course = urlParams.get("course");
  const date = urlParams.get("date");
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleFinish = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [formState, setFormState] = useState({
    course,
    date,
    cablifecycle: "Lead",
    marketingfunnel: "undefined",
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    reason: "",
    reasonDescription: "",
    kundennummer: "",
    bildungsgutschein: null,
    city: "Berlin",
  });
  const [openAgreeError, setOpenAgreeError] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const handleAgree = () => {
    setIsAgreed(!isAgreed);
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      course,
      date,
      firstname,
      lastname,
      email,
      bildungsgutschein,
    } = formState;

    if (
      isAgreed &&
      course &&
      date &&
      firstname &&
      lastname &&
      email &&
      // phone &&
      bildungsgutschein
      // marketingfunnel
    ) {
      // submit(formState);
    } else {
      if (!isAgreed) {
        setOpenAgreeError(true);
      }
    }

  };
  const handleClose = () => {
    setOpenAgreeError(false);
  };
  return (
    <Layout location={location} className={classes.root}>
      <Container maxWidth="md" className={classes.container} >
        <Stepper
          orientation={smDown ? "vertical" : "horizontal"}
          activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>

              <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={openAgreeError}
              >
                <DialogTitle id="simple-dialog-title">
                  Please check the terms agreement to proceed
                </DialogTitle>
              </Dialog>
              <Grid container spacing={4} justifyContent="center" alignItems="center">
                <ContactForm
                  formState={formState}
                  handleChange={handleChange}
                  setFormState={setFormState}
                  isAgreed={isAgreed}
                  handleAgree={handleAgree}
                  dates={nextStarts}
                  step={activeStep}
                  course={course}
                />
                {/* <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography> */}
                <Grid item xs={12} >
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                  {isStepOptional(activeStep) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSkip}
                      className={classes.button}
                    >
                      Skip
                    </Button>
                  )}

                  {activeStep === steps.length - 1 ? <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // onClick={handleFinish}
                    className={classes.button}
                  >
                    Submit
                  </Button> :
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  }
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default ContactPage

export const contactPageQuery = graphql`
        query ContactFormNextStartsDateQuery {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "nextStarts" } } }
        ) {
          edges {
            node {
              frontmatter {
                nextStarts {
                  date
                  fdate(formatString: "DD MMMM YYYY")
                  course
                }
              }
            }
          }
        }
      }
`;

// <Layout location={location} title="Apply">
//   <SEO
//     title={"Contact Code Academy Berlin"}
//     location={location}
//     description={"Contact Code Academy Berlin for more information"}
//   />
//   <Container maxWidth="lg" >
//     <HeaderSection
//       header="talk to an advisor"
//       subheader=""
//       location={location}
//     />

//     <BookingModal />
//     <HeaderSection
//       header="apply for a course"
//       subheader=""
//       location={location}
//     />
//     <HeaderSection
//       header="get an offer"
//       subheader=""
//       location={location}
//     />
//   </Container>
// </Layout>

